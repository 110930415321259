import PropTypes from 'prop-types'
import React from 'react'

import '../css/typed-text.scss'

class TypedText extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      typedText: ''
    }
  }

  type (character) {
    const typedText = this.state.typedText + character
    this.setState({ typedText })
  }

  async startTyping () {
    const letters = this.props.text.split('')
    for (let i = 0; i < letters.length; i++) {
      const letter = letters[i]
      await new Promise(resolve => setTimeout(resolve, 100))
      this.type(letter)
    }
  }

  componentDidMount () {
    this.startTyping()
  }

  render () {
    return (<span className="blinking-caret">{this.state.typedText}</span>)
  }
}

TypedText.propTypes = {
  text: PropTypes.string
}

export default TypedText
